import React, { useEffect, useState } from 'react';
import { NotionRenderer } from 'react-notion';
import 'react-notion/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css'; // Optional: for syntax highlighting

const GptBitcoin = () => {
  const [notionData, setNotionData] = useState(null);

  useEffect(() => {
    // Fetch the Notion page data from the Cloudflare Worker
    async function fetchNotionPage() {
      const response = await fetch(
        'https://notion-api-worker.jocoding.workers.dev/v1/page/GPT-AI-240567bebf704f29adb6cc7bd61962fc'
      );
      const data = await response.json();
      setNotionData(data);
    }

    fetchNotionPage();
  }, []);

  useEffect(() => {
    // After the Notion data is rendered, modify all links
    const links = document.querySelectorAll('a.notion-link');
    links.forEach((link) => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    });
  }, [notionData]); // Run this effect after notionData is updated

  if (!notionData) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ maxWidth: '768px', margin: '0 auto' }}>
      <NotionRenderer blockMap={notionData} />
    </div>
  );
};

export default GptBitcoin;